import Axios from "axios";
import { isArrayOf, isNumber, isObject, isString } from "./utils/narrowing";

export const Api = Axios.create({
  baseURL:
    process.env["BACKEND_URL"] ??
    "https://dev.cloudschooling.it/libroliquido/api/",
});

export interface BookSummaryBook {
  id: number;
  title: string;
  isbn: string;
}

export interface BookSummary {
  book: BookSummaryBook;
  chapters: ChapterData["toc"][];
}

function isBookSummary(data: unknown): data is BookSummary {
  return (
    isObject(data) &&
    "book" in data &&
    isObject(data.book) &&
    "id" in data.book &&
    isNumber(data.book.id) &&
    "title" in data.book &&
    isString(data.book.title) &&
    "isbn" in data.book &&
    isString(data.book.isbn) &&
    "chapters" in data &&
    isArrayOf(data.chapters, isChapterTOC)
  );
}

export async function getBookSummary(isbn: string): Promise<BookSummary> {
  const result = await Api.get(`v1/books/${isbn}/`);

  if (!isBookSummary(result.data)) {
    throw new Error("Data is not a BookSummary");
  }
  return result.data;
}

export async function getChapter(chapterId: number): Promise<ChapterData> {
  const result = await Api.get(`v1/chapters/${chapterId}/`);

  if (!isChapterData(result.data)) {
    throw new Error("Data is not a ChapterData");
  }
  return result.data;
}

export interface ChapterData {
  id: number;
  book?: number | undefined;
  order: number;
  title: string;
  toc: ChapterTOC;
  // TODO
  content: unknown;
}

function isChapterData(data: unknown): data is ChapterData {
  return (
    isObject(data) &&
    "id" in data &&
    isNumber(data.id) &&
    (!("book" in data) || data.book === undefined || isNumber(data.book)) &&
    "order" in data &&
    isNumber(data.order) &&
    "title" in data &&
    isString(data.title) &&
    "toc" in data &&
    isChapterTOC(data.toc) &&
    "content" in data
  );
}

export interface ChapterTOC {
  id: number;
  title: string;
  slug: string;
  mylim_id: number | null;
  children: ChapterTOC[];
}

function isChapterTOC(data: unknown): data is ChapterTOC {
  return (
    isObject(data) &&
    "title" in data &&
    isString(data.title) &&
    "slug" in data &&
    isString(data.slug) &&
    "mylim_id" in data &&
    (data.mylim_id === null || isNumber(data.mylim_id)) &&
    "children" in data &&
    isArrayOf(data.children, isChapterTOC)
  );
}

export async function saveChapter(
  chapterId: string,
  data: Readonly<ChapterData>,
): Promise<unknown> {
  const result = await Api.put(`v1/chapters/${chapterId}/`, data);

  return result.data;
}

export async function postChapter(
  bookId: number,
  order: number,
): Promise<ChapterData> {
  const result = await Api.post("v1/chapters/", {
    title: "Nuovo capitolo",
    book: bookId,
    order,
  });

  if (!isChapterData(result.data)) {
    throw new Error("Invalid returned chapter data");
  }
  return result.data;
}

export async function apiDeleteChapter(chapterId: number): Promise<void> {
  await Api.delete(`v1/chapters/${chapterId}`);
}

export async function fetchMyLIMContentForSection(
  isbn: string,
  sectionID: string,
): Promise<unknown> {
  const url = `https://www.cloudschooling.it/mialim2/api/v1/book/audiolibri/?isbn=${isbn}&sezione=${sectionID}`;
  const result = await Api.get(url);

  return result.data;
}

export async function apiUploadImage(
  name: string,
  formData: Readonly<FormData>,
): Promise<unknown> {
  const result = await Api.post(`v1/image/${name}/`, formData);

  return result.data;
}
