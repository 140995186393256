import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../Auth";

import logoLoescher from "~/src/assets/logo-loescher.png";
import logoDanna from "~/src/assets/logo-danna.png";
import { setThemeFromConf } from "../utils/themes";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);
  const clearErrorMsg = () => setErrorMsg(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setThemeFromConf({
      themeColor: 'light'
    });
  }, []);

  const { login } = useAuth();

  const onLogin = async (evt) => {
    clearErrorMsg();
    evt.preventDefault();
    try {
      await login(username, password, rememberMe);
    } catch (err) {
      setErrorMsg(
        err?.message || "Si è verificato un errore, riprovare più tardi.",
      );
      return;
    }
    navigate(location?.state?.from || "/", { replace: true });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-between py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h1 className="mt-6 text-center text-2xl">
            <a
              href="https://iwpiu.loescher.it/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <IwPiuLogo height="5rem" className="mx-auto mb-2" />
              <span className="font-bold">Libro accessibile</span> su{" "}
              <span className="font-bold text-red-mylim">Libroliquido</span>
            </a>
          </h1>
        </div>
        <form
          className="mt-8 mx-auto space-y-6 w-4/5 flex flex-col"
          action="#"
          onSubmit={onLogin}
        >
          {errorMsg && (
            <div className="alert alert-error shadow-lg">
              <div>
                <span>{errorMsg}</span>
              </div>
            </div>
          )}
          <div className="space-y-4">
            <p className="text-sm text-center">
              Per accedere inserisci e-mail e password
            </p>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Indirizzo email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Indirizzo email"
                value={username}
                onChange={(evt) => setUsername(evt.target.value)}
                className="w-full px-3 py-2 border border-gray-900 placeholder-gray-400 text-gray-900 rounded focus:outline-none focus:ring-red-mylim focus:border-red-mylim focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                placeholder="Password"
                className="w-full px-3 py-2 border border-gray-900 placeholder-gray-400 text-gray-900 rounded focus:outline-none focus:ring-red-mylim focus:border-red-mylim focus:z-10 sm:text-sm"
              />
            </div>
            <div className="form-control">
              <label
                htmlFor="remember-me"
                className="label cursor-pointer justify-center"
              >
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe((checked) => !checked)}
                  className="checkbox text-gray-900"
                />
                <span className="label-text ml-4 text-gray-900">Ricordami</span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="h-12 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-mylim focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-mylim"
          >
            Entra
          </button>
        </form>
        <p className="text-center">
          <a
            href="https://iwpiu.loescher.it/iswrecupero"
            target="_blank"
            rel="noreferrer"
          >
            Hai perso la password?
          </a>
        </p>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex justify-around items-center max-w-md w-full my-6 h-20">
          <a
            className="w-auto h-full"
            href="https://www.loescher.it/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-auto h-full"
              src={logoLoescher}
              alt="Logo Loescher Editore"
            />
          </a>
          <a
            className="w-auto h-full"
            href="https://www.danna.it/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-auto h-full"
              src={logoDanna}
              alt="Logo Danna Editore"
            />
          </a>
        </div>
        <span className="text-neutral">
          <a
            href="https://www.loescher.it/privacy"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            ▸ Informazioni sulla privacy
          </a>
        </span>
      </div>
    </div>
  );
}

function IwPiuLogo({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
    >
      <circle cx="15" cy="15" r="15" fill="#ffb400" strokeWidth=".333" />
      <g fill="#0d0e0e" strokeWidth=".333">
        <path d="m1.96 18.2 0.732-4.12h1.5l-0.698 4.12zm0.931-5.09 0.166-1.06h1.63l-0.399 1.4z" />
        <path d="m11.4 14-2 4.12h-1.6c-0.133-0.299-0.266-0.865-0.399-1.53l-0.798 1.53h-1.5c-0.166-0.499-0.432-1.66-0.432-2.86 0-0.432 0.0333-0.865 0.0998-1.26h1.53c-0.0665 0.499-0.0998 0.931-0.0998 1.36 0 0.432 0.0333 0.798 0.0665 1.23h0.0998l0.831-1.73c0-0.299 0.0333-0.599 0.0998-0.865h1.53c-0.0665 0.499-0.0998 0.931-0.0998 1.36 0 0.432 0.0333 0.798 0.0665 1.23h0.0998l0.965-2.59z" />
        <path d="m12.4 11.2h3.03c1.5 0 2.33 0.931 2.33 2.29 0 1.46-1.2 2.86-3.49 2.86h-0.466l-0.299 1.8h-2.29zm2 1.73-0.299 1.73c0.765-0.0333 1.23-0.266 1.23-0.998 0-0.466-0.299-0.698-0.931-0.732z" />
        <path d="m17.6 18.2 0.998-5.49h2l-0.931 5.49zm1.23-6.78 0.2-1.4h2.2l-0.532 1.86z" />
        <path d="m26.9 12.7-0.998 5.49h-2l0.166-0.931c-0.732 0.699-1.36 1.06-2.16 1.06-0.632 0-0.998-0.499-0.998-1.33 0-0.2 0.0333-0.432 0.0664-0.632l0.599-3.63h2.06l-0.432 2.53c-0.0666 0.366-0.0999 0.798-0.0333 1.06l0.166 0.0333c0.266-0.0997 0.632-0.266 1.03-0.565l0.532-3.09zm-3.16-0.831-1.83-1.9h1.9l1.33 1.9z" />
      </g>
    </svg>
  );
}

IwPiuLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.className,
};

export default Login;
