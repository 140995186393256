import React, { ReactElement } from "react";
import { Editor, Transforms, Element } from "slate";
import { useSlate } from "slate-react";
import { MdChatBubble } from "react-icons/md";

import { isBlockActive } from "../../utils";
import ButtonBase from "../../../components/Button";
import Icon from "../../../components/Icon";

function ToolbarBtnGlossario(): ReactElement {
  const editor = useSlate();

  const isActive = isBlockActive(editor, "glossario");

  const onToggle = () => {
    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          "type" in n &&
          n.type === "glossario",
      });

      return;
    }

    const btn = {
      type: "glossario",
      description: [{ text: "Nota ..." }],
      children: [],
    };

    Transforms.wrapNodes(editor, btn, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  };

  return (
    <ButtonBase
      square
      type="primary"
      outline={!isActive}
      ariaLabel="blocco glossario"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onToggle}
    >
      <Icon label="blocco glossario" icon={MdChatBubble} size="30px" />
    </ButtonBase>
  );
}

export default ToolbarBtnGlossario;
