import React, { ReactElement } from "react";

import Button from "./ButtonBase";
import IconCmpt from "../Icon";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../Tooltip";

export interface Props {
  readonly onClick?: (() => void) | undefined;
  readonly Icon: (props: IconProps) => ReactElement;
  readonly ariaLabel?: string | undefined;
}

export interface IconProps {
  readonly size: "30px";
}

/**
 * Componente per i pulsanti di azione (es: sposta, elimina, preview blocco per aggiunta/trasformazione)
 */
function ButtonAction({ onClick, Icon, ariaLabel }: Props) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          square
          outline
          type="primary"
          onClick={onClick}
          ariaLabel={ariaLabel}
        >
          <IconCmpt label={ariaLabel} icon={Icon} size="30px" />
        </Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={2} side="top">
        {ariaLabel}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
}

export default React.memo(ButtonAction);
