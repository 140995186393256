import { Transforms } from "slate";
import { ELEMENT_TYPES } from "../constants";
import { makeDeserializer } from "./makeDeserializer";
import { ReactEditor } from "slate-react";

export function withDocxDeserializer(editor: ReactEditor) {
  const { insertData } = editor;
  const deserialize = makeDeserializer();

  editor.insertData = (data) => {
    const html = data.getData("text/html");

    // Incolla da Slate
    if (html.includes("data-slate-fragment")) {
      insertData(data);
      return;
    }

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);

      if (!Array.isArray(fragment)) {
        return;
      }

      const firstFragment = fragment[0];
      if (firstFragment === undefined || typeof firstFragment !== "object") {
        return;
      }

      if (
        "type" in firstFragment &&
        firstFragment.type !== ELEMENT_TYPES.paragraph
      ) {
        Transforms.insertNodes(
          editor,
          fragment.filter((node) => typeof node !== "string"),
        );
      } else if ("children" in firstFragment) {
        // Se è un paragrafo incollo i children [{ text: 'prova' }] invece che l'intero paragrafo per
        // incollare il contenuto dove si trova il cursore e non in un nuovo paragrafo sotto
        Transforms.insertNodes(editor, firstFragment.children);
        Transforms.insertNodes(
          editor,
          fragment.slice(1).filter((node) => typeof node === "object"),
        );
      }

      return;
    }

    insertData(data);
  };

  return editor;
}
