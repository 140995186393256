import { ELEMENT_TYPES } from "../constants";

export const ELEMENT_TAGS = {
  A: (el: Element) => ({
    type: ELEMENT_TYPES.link,
    url: el.getAttribute("href"),
  }),
  H1: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 1 }),
  H2: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 2 }),
  H3: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 3 }),
  H4: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  H5: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  H6: (_el: Element) => ({ type: ELEMENT_TYPES.heading, weight: 4 }),
  LI: (_el: Element) => ({ type: ELEMENT_TYPES.listItem }),
  P: (_el: Element) => ({ type: ELEMENT_TYPES.paragraph }),
  UL: (_el: Element) => ({ type: ELEMENT_TYPES.unorderedList }),
} as const;

export type TagAttributes = { italic: true } | { bold: true };

export const TEXT_TAGS = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  B: () => ({ bold: true }),
  STRONG: () => ({ bold: true }),
} as const;
