import React, { PropsWithChildren, useCallback } from "react";

export interface FurthestVisibleHeading {
  id: string;
  disabled?: undefined | boolean;
}

export interface Context {
  headingsAvailable: { slug: string }[];
  furthestVisibleHeading: FurthestVisibleHeading;
  setFurthestVisibleHeading: (
    furthestVisibleHeading: FurthestVisibleHeading,
  ) => void;
}

export const VisibleHeadingContext = React.createContext<Context>({
  headingsAvailable: [],
  furthestVisibleHeading: { id: "", disabled: false },
  setFurthestVisibleHeading: () => undefined,
});

export interface VisibleHeadingContextProviderProps {
  value: Context;
}

export function VisibleHeadingContextProvider({
  children,
  value,
}: PropsWithChildren<VisibleHeadingContextProviderProps>) {
  return (
    <VisibleHeadingContext.Provider value={value}>
      {children}
    </VisibleHeadingContext.Provider>
  );
}

function useVisibleHeadings() {
  const {
    headingsAvailable,
    furthestVisibleHeading,
    setFurthestVisibleHeading,
  } = React.useContext(VisibleHeadingContext);

  const resetHeading = useCallback(() => {
    setFurthestVisibleHeading({ id: "", disabled: true });
  }, [setFurthestVisibleHeading]);

  const addHeading = useCallback(
    (headingToAdd: Readonly<AddableHeading>) => {
      if (headingToAdd.rect.top >= 10) {
        // 10 è una tolleranza trovata empiricamente che sembra funzionare
        setFurthestVisibleHeading({ id: headingToAdd.id, disabled: false });
      }
    },
    [setFurthestVisibleHeading],
  );

  const removeHeading = useCallback(
    (headingToRemove: Readonly<AddableHeading>) => {
      if (headingToRemove.rect.bottom === window.innerHeight) {
        const index = headingsAvailable.findIndex(
          (h) => h.slug === headingToRemove.id,
        );
        const lastHeading = headingsAvailable[index - 1];
        if (lastHeading !== undefined)
          setFurthestVisibleHeading({
            id: lastHeading.slug,
            disabled: false,
          });
      }
    },
    [headingsAvailable, setFurthestVisibleHeading],
  );

  return {
    furthestVisibleHeading,
    resetHeading,
    removeHeading,
    addHeading,
  };
}

export interface AddableHeading {
  id: string;
  rect: {
    top: number;
    bottom: number;
  };
}

export default useVisibleHeadings;
