import React, { AnchorHTMLAttributes } from "react";
import { Transforms } from "slate";
import * as Slate from "slate";
import { useReadOnly, ReactEditor, useSlateStatic } from "slate-react";
import * as Popover from "@radix-ui/react-popover";

export type BlockLinkNode = Slate.Node & {
  url: string;
};

export interface Props {
  /**
   * Passati e gestiti da Slate.js
   */
  children: unknown;
  element: BlockLinkNode;

  /**
   * Passati e gestiti da Slate.js
   */
  attributes: AnchorHTMLAttributes<HTMLAnchorElement>;
}

/**
 * Componente che renderizza un fumetto sopra a una o più parole per indicare un link associato ad essi.
 */
function BlockLink({ attributes, element, children }: Props) {
  const editor = useSlateStatic() as ReactEditor;
  const isReadOnly = useReadOnly();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes<BlockLinkNode>(
      editor,
      { url: e.target.value },
      { at: path },
    );
  };

  const fixUrl = (open: boolean) => {
    if (!open) {
      const isValid = /https?:\/\//.exec(element.url);

      if (!isValid) {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes<BlockLinkNode>(
          editor,
          { url: `https://${element.url}` },
          { at: path },
        );
      }
    }
  };

  if (isReadOnly) {
    return (
      <a
        {...attributes}
        href={element.url}
        target="_blank"
        rel="noreferrer noopener"
      >
        {children}
      </a>
    );
  }

  return (
    <Popover.Root onOpenChange={fixUrl}>
      <Popover.Trigger>
        <span {...attributes} className="underline">
          {children}
        </span>
      </Popover.Trigger>
      <Popover.Content
        side="top"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        className="z-editor-dialog"
      >
        <input
          aria-label="url blocco link"
          placeholder="https://example.com"
          className="input input-primary input-bordered"
          type="text"
          value={element.url}
          onChange={onChange}
        />
      </Popover.Content>
    </Popover.Root>
  );
}

export default BlockLink;
