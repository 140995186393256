import React, { ReactNode } from "react";

import { MdClose } from "react-icons/md";
import Icon from "~/src/components/Icon";

import * as DialogPrimitive from "@radix-ui/react-dialog";

export interface Props {
  /**
   * Titolo del dialog
   */
  readonly title?: string | undefined;

  /**
   * Elemento che triggera il dialog
   */
  readonly trigger: ReactNode;

  /**
   * Il contenuto del dialog
   */
  readonly children: ReactNode;
}

/**
 * Renderizza una modale
 */
function Dialog({ title, trigger, children, ...props }: Props) {
  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <DialogPrimitive.Overlay className="fixed w-full h-full bg-gray-900 opacity-50" />
      <DialogPrimitive.Content
        {...props}
        className="fixed w-3/4 lg:w-1/2 right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 rounded-md flex flex-col justify-center p-6 bg-base-100"
      >
        <div className="w-full flex justify-between align-middle">
          <DialogPrimitive.Title asChild>
            <h3 className="flex-1 text-center text-lg font-bold pt-2">
              {title ?? ""}
            </h3>
          </DialogPrimitive.Title>
          <DialogPrimitive.Close className="btn btn-ghost btn-square btn-sm text-neutral">
            <Icon label="chiudi" icon={MdClose} />
          </DialogPrimitive.Close>
        </div>
        <div className="my-6">{children}</div>
      </DialogPrimitive.Content>
    </DialogPrimitive.Root>
  );
}

export default Dialog;
