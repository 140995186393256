/**
 * Funzioni per settare i parametri di customizzazione globale dello stile
 * definiti in `styles/themes.css`.
 *
 * Cosa può customizzare l'utente?
 *
 */

import { isObject, isString } from "./narrowing";

export type CustomizableParameter =
  | "font-family"
  | "font-case"
  | "font-size"
  | "font-space"
  | "theme";

export type FontFamily = "serif" | "sans" | "readable";
export type FontCase = "normal" | "uppercase";
export type FontSize = "0" | "1" | "2";
export type FontSpace = "normal" | "large" | "double";
export type Theme = "light" | "dark" | "retro";

/**
 * Setta un attributo customizzabile dall'utente ad un valore.
 * @param attr - l'attributo da customizzare
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setThemeAttribute(
  attr: CustomizableParameter,
  val?: string,
  element?: HTMLElement,
) {
  const el = element ?? document.documentElement;
  if (val === undefined) {
    el.removeAttribute(`data-${attr}`);
  } else {
    el.setAttribute(`data-${attr}`, val);
  }
}

/**
 * Setta la famiglia del carattere.
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setFontFamily(val: FontFamily, element?: HTMLElement) {
  setThemeAttribute("font-family", val, element);
}

/**
 * Setta il case del carattere.
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setFontCase(val: FontCase, element?: HTMLElement) {
  setThemeAttribute("font-case", val, element);
}
/**
 * Setta la dimensione del font.
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setFontSize(val: FontSize, element?: HTMLElement) {
  setThemeAttribute("font-size", val, element);
}

/**
 * Setta l'interlinea.
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setFontSpace(val: FontSpace, element?: HTMLElement) {
  setThemeAttribute("font-space", val, element);
}

/**
 * Setta il tema colori.
 * @param val - il valore dell'attributo
 * @param [element = html] - l'elemento del DOM a cui aggiungere l'attributo
 */
export function setThemeColor(val: Theme, element?: HTMLElement) {
  setThemeAttribute("theme", val, element);
}

export interface Config {
  fontCase: FontCase;
  fontFamily: FontFamily;
  fontSize: FontSize;
  fontSpace: FontSpace;
  themeColor: Theme;
}

/**
 * Setta il tema a partire da una configurazione completa
 * @param conf configurazione del tema
 */
export function setThemeFromConf(conf: Config) {
  setFontCase(conf.fontCase);
  setFontFamily(conf.fontFamily);
  setFontSize(conf.fontSize);
  setFontSpace(conf.fontSpace);
  setThemeColor(conf.themeColor);
}

export function stringIsFontCase(data: string): data is FontCase {
  return data === "normal" || data === "uppercase";
}

export function stringIsFontFamily(data: string): data is FontFamily {
  return data === "serif" || data === "sans" || data === "readable";
}

export function stringIsFontSize(data: string): data is FontSize {
  return data === "0" || data === "1" || data === "2";
}

export function stringIsFontSpace(data: string): data is FontSpace {
  return data === "normal" || data === "large" || data === "double";
}

export function stringIsTheme(data: string): data is Theme {
  return data === "light" || data === "dark" || data === "retro";
}

export function isConfig(data: unknown): data is Config {
  return (
    isObject(data) &&
    "fontCase" in data &&
    isString(data.fontCase) &&
    stringIsFontCase(data.fontCase) &&
    "fontFamily" in data &&
    isString(data.fontFamily) &&
    stringIsFontFamily(data.fontFamily) &&
    "fontSize" in data &&
    isString(data.fontSize) &&
    stringIsFontSize(data.fontSize) &&
    "fontSpace" in data &&
    isString(data.fontSpace) &&
    stringIsFontSpace(data.fontSpace) &&
    "themeColor" in data &&
    isString(data.themeColor) &&
    stringIsTheme(data.themeColor)
  );
}
