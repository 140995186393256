import React, { FunctionComponent, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function withAuthetication<P extends NonNullable<unknown>>(
  Component: FunctionComponent<P>,
): (props: P) => ReactElement {
  return function Authentication(props: P): ReactElement {
    const location = useLocation();
    const { userInfo } = useAuth();

    return (
      <>
        {userInfo.email ? (
          <Component {...props} />
        ) : (
          <Navigate to="/login" state={{ from: location }} replace />
        )}
      </>
    );
  };
}

export default withAuthetication;
