import React, { PropsWithChildren, useRef } from "react";
import { CSSTransition } from "react-transition-group";

export interface Props {
  in?: undefined | boolean;
}

function FadeAnimation({ in: inProp, children }: PropsWithChildren<Props>) {
  const animationRef = useRef<null | HTMLDivElement>(null);

  return (
    <CSSTransition
      in={inProp}
      timeout={300}
      unmountOnExit
      classNames="fade"
      nodeRef={animationRef}
    >
      <div ref={animationRef}>{children}</div>
    </CSSTransition>
  );
}

export { FadeAnimation };
