import React from "react";
import { Editor, Transforms, Element as SlateElement, Range } from "slate";
import { useSlate } from "slate-react";
import { MdLink } from "react-icons/md";

import { isBlockActive } from "../../utils";
import { ELEMENT_TYPES } from "../../constants";
import ButtonBase from "../../../components/Button";
import Icon from "../../../components/Icon";

function ToolbarBtnLink() {
  const editor = useSlate();

  const isActive = isBlockActive(editor, ELEMENT_TYPES.link);

  const onToggle = () => {
    const { selection } = editor;

    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          "type" in n &&
          n.type === ELEMENT_TYPES.link,
      });

      return;
    }

    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: ELEMENT_TYPES.link,
      url: "",
      children: isCollapsed ? [{ text: "" }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  return (
    <ButtonBase
      square
      type="primary"
      outline={!isActive}
      ariaLabel="blocco link"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onToggle}
    >
      <Icon icon={MdLink} label="blocco link" size="30px" />
    </ButtonBase>
  );
}

export default ToolbarBtnLink;
