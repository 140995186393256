import jwtDecode from "jwt-decode";

import { loginWithSsoToken } from "./MylimAuthenticationService";
import { isArrayOf, isNumber, isObject, isString } from "../utils/narrowing";

// Funzione per leggere/scrivere il localStorage

/**
 * Legge dal local storage
 */
function localStorageGet(key: string): string | null {
  return window.localStorage.getItem(key);
}

/**
 * Scrive nel localStorage
 */
function localStorageSet(key: string, val: string): void {
  window.localStorage.setItem(key, val);
}

/**
 * Cancella dal localStorage
 */
function localStorageDelete(key: string): void {
  window.localStorage.removeItem(key);
}

/**
 * Legge il token JWT da storage
 */
export function readToken(): string | null {
  return localStorageGet("token");
}

// Funzioni e tipi per manipolare il token

export interface Token {
  token: string;
  data: TokenData;
}

export interface TokenData {
  /** l'ID dell'utente. */
  user_id: number;

  /** l'username. */
  username: string;

  /** la mail dell'utente. */
  email: string;

  /** la data di scadenza del token (epoch). */
  exp: number;

  /** l'array di permessi dell'utente. */
  loescher_roles: string[];
}

function isTokenData(data: unknown): data is TokenData {
  return (
    isObject(data) &&
    "user_id" in data &&
    isNumber(data.user_id) &&
    "username" in data &&
    isString(data.username) &&
    "email" in data &&
    isString(data.email) &&
    "exp" in data &&
    isNumber(data.exp) &&
    "loescher_roles" in data &&
    isArrayOf(data.loescher_roles, isString)
  );
}

/**
 * Parsa il payload del JWT e ne verifica il formato
 * @param token il token JWT
 * @returns i dati parsati o null in caso di problemi
 */
export function parseTokenData(token: string | null): Token | null {
  if (!token) {
    return null;
  }

  let data: unknown;
  try {
    data = jwtDecode(token);
  } catch (err) {
    if (isObject(err) && "name" in err && err.name === "InvalidTokenError") {
      return null;
    }
    throw err;
  }

  // Controlliamo validità dei dati del token
  if (!isTokenData(data)) {
    return null;
  }
  return { token, data };
}

/**
 * Controlla se il token JWT è scaduto
 * @param token - il token JWT.
 * @returns true se il token è scaduto falso altrimenti.
 */
export function isTokenExpired(token: Token): boolean {
  return token.data.exp * 1000 <= Date.now();
}

export function saveToken(token: string): void {
  localStorageSet("token", token);
}

export function deleteToken(): void {
  localStorageDelete("token");
}

export const LOESCHER_SSO_COOKIE_NAME = "loescher_sso";
const LOESCHER_SSO_COOKIE_DOMAIN =
  process.env["NODE_ENV"] !== "development" &&
  location.hostname.endsWith("loescher.it")
    ? "loescher.it"
    : undefined;

export function saveLoescherSsoToken(
  token: string,
  maxAgeInSeconds?: number,
): void {
  let cookie = `${LOESCHER_SSO_COOKIE_NAME}=${token}; path=/`;
  if (LOESCHER_SSO_COOKIE_DOMAIN !== undefined) {
    cookie += `; domain=${LOESCHER_SSO_COOKIE_DOMAIN}`;
  } else {
    cookie += `; SameSite=Lax`;
  }

  if (maxAgeInSeconds !== undefined) {
    cookie += `; max-age=${maxAgeInSeconds}`;
  }

  document.cookie = cookie;
}

export function deleteLoescherSsoToken() {
  let cookie = `${LOESCHER_SSO_COOKIE_NAME}=; path=/; max-age=-1`;
  if (LOESCHER_SSO_COOKIE_DOMAIN !== undefined) {
    cookie += `; domain=${LOESCHER_SSO_COOKIE_DOMAIN};`;
  }
  document.cookie = cookie;
}

export function readLoescherSsoToken(): string | null {
  const cookieNameWithEqual = `${LOESCHER_SSO_COOKIE_NAME}=`;
  const cookieRow = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieNameWithEqual));
  if (cookieRow === undefined) {
    return null;
  }
  return cookieRow.split("=")[1] ?? null;
}

/**
 * Procedura per rinnovare il token di autenticazione con il token Loescher.
 * Se il token è rinnovato viene salvato il local storage. Se è impossibile
 * salvare il token => ritorna false.
 *
 * @returns true se il token è stato rinnovato, false altrimenti.
 */
export async function refreshToken(): Promise<boolean> {
  const ssoToken = readLoescherSsoToken();
  if (ssoToken === null) {
    return false;
  }

  try {
    const newToken = await loginWithSsoToken(ssoToken);
    saveToken(newToken);
    return true;
  } catch {
    return false;
  }
}

/**
 * Ottine un token utilizzabile.
 * Se il rinnovo è impossibile => ritorna null
 *
 * @param token - il token da controllare/rinnovare.
 * @returns a valid token or null.
 */
export async function getFreshToken(
  token: Token | null,
): Promise<Token | null> {
  if (!token) {
    return null;
  }
  if (!isTokenExpired(token)) {
    return token;
  }

  const tokenRefreshed = await refreshToken();
  return tokenRefreshed ? parseTokenData(readToken()) : null;
}
