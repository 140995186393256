import React, { ReactElement } from "react";

function SentryFallback(): ReactElement {
  return (
    <div className="flex justify-center items-center">
      <p>&Egrave; stato riscontrato un errore. Prova a ricaricare la pagina.</p>
    </div>
  );
}

export default SentryFallback;
