import React, { PropsWithChildren, ReactElement } from "react";
import { useFocused, useSelected } from "slate-react";
import { ReadonlyDeep } from "~/src/utils/reflection";

export interface Context {
  buttons: ReactElement[];
  setButtons: null | ((buttons: ReadonlyDeep<ReactElement[]>) => void);
}

/**
 * Il contesto React a cui i blocchi accederanno tramite l'hook `useToolbar`.
 *
 * NOTA: Questo contesto DEVE essere innestato dentro ad un Editor di SlateJS
 * poichè fa uso dell'hook `useSelected` di SlateJS.
 */
export const ToolbarContext = React.createContext<ReadonlyDeep<Context>>({
  buttons: [],
  setButtons: null,
});

export interface ToolbarContextProviderProps {
  value: ReadonlyDeep<Context>;
}

export function ToolbarContextProvider({
  children,
  value,
}: ReadonlyDeep<PropsWithChildren<ToolbarContextProviderProps>>) {
  return (
    <ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>
  );
}

const EMPTY_BUTTONS: ReadonlyDeep<ReactElement[]> = [];

/**
 * Hook per gestire la toolbar con gli strumenti di formattazione inline.
 * L'hook viene chiamato all'interno dei blocchi per definire se e quali bottoni
 * saranno disponibili per la formattazione inline del contenuto del blocco.
 *
 * Esempio:
 * ```
 *   useToolbar([<ToolbarBtn props={...} />, <ToolbarBtn props={...} />])
 * ```
 * oppure ignorando il parametro per non visualizzare la toolbar con gli
 * gli strumenti di formattazione.
 * ```
 *   useToolbar()
 */
function useToolbar(btns: ReadonlyDeep<ReactElement[]> = EMPTY_BUTTONS) {
  const isSelected = useSelected();
  const isEditorFocused = useFocused();
  const { setButtons } = React.useContext(ToolbarContext);

  React.useEffect(() => {
    if (setButtons === null) {
      return;
    }
    if (!isEditorFocused) setButtons([]);
    else if (isSelected) setButtons(btns);
  }, [isSelected, isEditorFocused, setButtons, btns]);
}

export default useToolbar;
