import React from "react";

export interface Props {
  /**
   * Il nome del colore visualizzato (accessibile).
   */
  name: string;
  /**
   * Il valore hex del colore da visualizzare.
   */
  color: string;
}

function ColorSample({ name, color }: Props) {
  return (
    <span className="flex flex-col">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 512 384"
        className="h-6"
        style={{ color }}
      >
        <rect fill="currentColor" x="0" width="512" height="384" rx="32" />
      </svg>
      <span className="text-xs mt-1">{name}</span>
    </span>
  );
}

export default ColorSample;
