import { Editor, Element, Transforms, Node, Path } from "slate";
import { ReactEditor } from "slate-react";

import {
  BLOCKS_DEFAULT_ENTER,
  ELEMENT_TYPES,
  ElementTypesValues,
} from "./constants";
import { PartialProps } from "../utils/reflection";
import { isString } from "../utils/narrowing";

export function isBlockActive(editor: Editor, format: string) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      "type" in n &&
      isString(n.type) &&
      n.type === format,
  });

  return !!match;
}

export function isMarkActive(editor: Editor, format: string) {
  const marks = Editor.marks(editor);
  return marks ? (marks as Record<string, unknown>)[format] === true : false;
}

/**
 * Muove alla fine del blocco dove si trova la selection
 */
export function moveToEndBlockSelection(editor: Editor) {
  if (editor.selection?.anchor.path) {
    const {
      anchor: { path: pathAnchor },
    } = editor.selection;
    Transforms.select(editor, Editor.end(editor, pathAnchor));
  }
}

/**
 * Ritorna se il blocco attuale deve utilizzare il comportamente enter di default di Slate.js
 */
export function isBlockDefaultEnter(editor: Editor) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      "type" in n &&
      isString(n.type) &&
      (BLOCKS_DEFAULT_ENTER as readonly string[]).includes(n.type),
  });

  return !!match;
}

export interface AddBlockAndFocusArgs {
  editor: ReactEditor;
  path: [number, ...number[]];
  insertNodes: (nodes: number[]) => void;
  indexToFocus: number;
  cursorToEnd?: boolean | undefined;
}

/**
 * Funzione che aggiunge un blocco e lo mette in focus.
 * @param editor Instanza Editor
 * @param path Path dopo il quale si vuole inserire il nuovo blocck
 * @param insertNodes Callback che chiama il metodo quando è il momento di inserire i nodi. Viene passato
 * come parametro il nuovo percorso. Verra quindi chiamata ad esempio con insertNodes([1, 4, 5]) e il corpo della funzione
 * dovrà occuparsi di inserire i nodi che le interessano in quel path.
 * @param [indexToFocus] Se necessario andare in focus non sul nuovo blocco o sul primo di figlio. Se è un blocco semplice
 * o con solo un figlio non occorre passare niente, mentre se il blocco ha ad esempio due figli e si vuole andare in focus sul secondo
 * basta passare il valore "1"
 * @param cursorToEnd Se true posiziona il cursore alla fine del blocco.
 */
export const addBlockAndFocus = ({
  editor,
  path,
  insertNodes,
  indexToFocus,
  cursorToEnd = false,
}: AddBlockAndFocusArgs) => {
  const newPath = [...path] as typeof path;
  // Reason: newPath has at least one element because of its type.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  newPath[newPath.length - 1] = newPath[newPath.length - 1]! + 1;

  if (!ReactEditor.isFocused(editor)) {
    ReactEditor.focus(editor);
  }

  insertNodes(newPath);

  const pathFocus = indexToFocus ? [...newPath, indexToFocus] : newPath;

  if (cursorToEnd) {
    Transforms.select(editor, Editor.end(editor, pathFocus));
    return;
  }

  Transforms.select(editor, {
    anchor: Editor.start(editor, pathFocus),
    focus: Editor.start(editor, pathFocus),
  });
};

export function stringifyNode(
  node: { children: Node[] },
  { separator = " " } = {},
): string {
  const string = node.children.map((n) => Node.string(n)).join(separator);

  if (separator !== " ") {
    return string
      .normalize("NFKD")
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .trim()
      .replace(/[-\s]+/g, separator);
  }

  return string;
}

interface EditorSection {
  /** Peso della sezione */
  weight: number;

  /** Sottosezioni della sezione */
  children: EditorSection[];

  /** Titolo della sezione */
  titolo: string;

  /** Id della sezione */
  id: number;

  flatChildrenSlugs: string[];
  slug: string;
}

interface BuildSummaryNodeResult<T> {
  /** Sottosezione aggiunta o meno */
  added: boolean;
  /** Sottosezione precedente aggiornata. */
  prevSection: T;
}

/**
 * Funzione ricorsiva che aggiungere la nuova sezione del sommario al posto giusto, mantenendo ordinati i titoli per peso.
 * @param prevSection - Sezione precedente;
 * @param newSection - Nuova sezione;
 * @returns risultato della ricorsione.
 */
function buildSummaryNode<
  T extends Pick<EditorSection, "weight" | "flatChildrenSlugs" | "slug"> & {
    children: T[];
  },
>(prevSection: T, newSection: T): BuildSummaryNodeResult<T> {
  const updatedPrevSection = prevSection;

  if (
    updatedPrevSection.children.length === 0 &&
    updatedPrevSection.weight < newSection.weight
  ) {
    updatedPrevSection.children.push(newSection);
    updatedPrevSection.flatChildrenSlugs.push(newSection.slug);
    return { prevSection: updatedPrevSection, added: true };
  }

  if (updatedPrevSection.weight < newSection.weight) {
    // Aggiunge sempre all'ultimo children
    const result = buildSummaryNode(
      // Reason: in case there are no children, we already returned.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updatedPrevSection.children[updatedPrevSection.children.length - 1]!,
      newSection,
    );
    if (result.added) {
      updatedPrevSection.children[updatedPrevSection.children.length - 1] =
        result.prevSection;
      updatedPrevSection.flatChildrenSlugs = [
        ...new Set(
          updatedPrevSection.flatChildrenSlugs.concat(
            result.prevSection.flatChildrenSlugs,
          ),
        ),
      ]; // remove duplicates
    } else {
      updatedPrevSection.children.push(newSection);
      updatedPrevSection.flatChildrenSlugs.push(newSection.slug);
    }

    return { prevSection: updatedPrevSection, added: true };
  }

  return { prevSection: updatedPrevSection, added: false };
}

export interface Heading {
  /** Id del blocco */
  id: string;
  /** Ancora interna al blocco */
  slug: string;
  /** Tipo del blocco */
  type: string;
  /** Peso del blocco heading */
  weight: number;
  /** Testi del blocco */
  children: Node[];

  mylim_id?: number | null | undefined;
}

export interface HeadingChild {
  /** Testo del blocco */
  text: string;
}

export interface SummarySection {
  id: string;
  title: string;
  mylim_id: number | null;
  slug: string;
  weight: number;
  flatChildrenSlugs: string[];
  children: SummarySection[];
}

/**
 * Funzione che riceve ogni blocco heading e genera la struttura del sommario.
 * @param availableHeadings - Lista di blocchi;
 * @returns result - Sommario generato.
 */
export const buildSummary = (
  availableHeadings: Heading[] = [],
): SummarySection[] => {
  const summary: SummarySection[] = [];

  for (const currentBlock of availableHeadings) {
    const currentSection = {
      id: currentBlock.id,
      title: stringifyNode(currentBlock),
      mylim_id: currentBlock.mylim_id ?? null,
      slug: currentBlock.slug,
      weight: currentBlock.weight,
      flatChildrenSlugs: [],
      children: [],
    } satisfies SummarySection;

    let lastSection = summary[summary.length - 1];

    if (
      lastSection !== undefined &&
      lastSection.weight < currentSection.weight
    ) {
      lastSection = buildSummaryNode(lastSection, currentSection).prevSection;
    } else {
      summary.push(currentSection);
    }
  }

  return summary;
};

/**
 * Ritorna se due array hanno lo stesso contenuto
 */
export function arraysEqual<T>(
  a: readonly T[] | null | undefined,
  b: readonly T[] | null | undefined,
): boolean {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  for (let i = a.length - 1; i >= 0; i -= 1) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

interface CustomElement extends Element {
  type: ElementTypesValues;
  mylim_id: number | null;
  weight: number;
}

/**
 * Ritorna un titolo in formato Slate.js
 */
function createHeader(
  title: string,
  mylimId: number | null,
  level: number,
): CustomElement {
  return {
    type: ELEMENT_TYPES.heading,
    mylim_id: mylimId,
    children: [{ text: title }],
    weight: level + 1,
  };
}

/**
 * Funzione che genera un array di titoli in formato Slate.js a partire dal toc.
 * @param toc Array di titoli dalle api
 * @param headers Array che la funziona sta popolando
 * @param level livello di ricorsione
 */
export function createEditorContentsFromToc(
  toc: SummarySection[],
  headers: unknown[] = [],
  level = 0,
) {
  toc.forEach((section) => {
    headers.push(createHeader(section.title, section.mylim_id, level));

    if (section.children.length) {
      createEditorContentsFromToc(section.children, headers, level + 1);
    }
  });

  if (headers.length === 0) {
    return [{ type: ELEMENT_TYPES.paragraph, children: [{ text: "" }] }];
  }

  return headers;
}

type SaveableSummarySection = Omit<
  SummarySection,
  "weight" | "flatChildrenSlugs" | "children"
> & { children: SaveableSummarySection[] };

/**
 * Funzione che parsa il toc e rimuove i parametri extra prima di inviarli a backend
 */
export function parseTocToSave(toc: SummarySection): SaveableSummarySection {
  type PartiallyMangled = Omit<
    PartialProps<SummarySection, "weight" | "flatChildrenSlugs">,
    "children"
  > & { children: SummarySection["children"] | PartiallyMangled[] };

  const properties: PartiallyMangled = { ...toc };
  delete properties.weight;
  delete properties.flatChildrenSlugs;

  if (properties.children.length > 0) {
    properties.children = properties.children.map((t) =>
      parseTocToSave(t as SummarySection),
    );
  }

  return properties;
}

/**
 * Funzione che cerca un nodo di tipo `type` risalendo la catena dei nodi
 * `previous` a partire da un `path`. Se un tale nodo non esiste, ritorna null.
 * Se il nodo indicato da `path` soddisfa il requisito sul tipo => ritorna quel
 * nodo.
 * @param path - la path del nodo da cui far partire la ricerca.
 * @param root - il nodo root al di sotto del quale avviene la ricerca.
 * @param type - il tipo del nodo cercato.
 * @returns il primo nodo di tipo `type` incontrato risalendo l'albero o `null`.
 */
export function findPreceedingNodeByType<
  T extends Node & { type: ElementTypesValues },
>(path: readonly number[], root: T, type: string): T | null {
  if (path[0] === -1) {
    return null;
  }
  let p = [...path];
  for (;;) {
    const node = Node.get(root, p);
    if ("type" in node && node.type === type) {
      return node as T;
    }

    if (!Path.hasPrevious(p)) {
      return null;
    }

    p = Path.previous(p);
  }
}
