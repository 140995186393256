import Axios from "axios";
import { isObject, isString } from "../utils/narrowing";

/**
 * Funzioni per interagire con l'API di autenticazione
 */

const MylimLoginService = Axios.create({
  timeout: 1000,
});

export interface LoginOutput {
  token: string;
  token_loescher: string;
}

function isLoginOutput(data: unknown): data is LoginOutput {
  return (
    isObject(data) &&
    "token" in data &&
    isString(data.token) &&
    "token_loescher" in data &&
    isString(data.token_loescher)
  );
}

/**
 * Endpoint autenticazione Loescher
 * @param username - l'username
 * @param password - la password
 * @returns i token CS e Loescher o eccezione Axios
 */
export async function login(
  username: string,
  password: string,
): Promise<LoginOutput> {
  const res = await MylimLoginService.post(
    "https://www.cloudschooling.it/loescher/api/v2/api-token-auth/",
    {
      username,
      password: password,
      app: "libroliquido",
    },
  );

  if (!isLoginOutput(res.data)) {
    throw new Error("Unexpected data from login API");
  }
  return res.data;
}

/**
 * Endpoint autenticazione Loescher tramite token SSO
 * @returns il token JWT o eccezione Axios
 */
export async function loginWithSsoToken(token: string): Promise<string> {
  const res = await MylimLoginService.post(
    "https://www.cloudschooling.it/loescher/api/v2/sso-login/",
    {
      sso_token: token,
      app: "libroliquido",
    },
  );

  if (isObject(res.data) && "token" in res.data && isString(res.data.token)) {
    return res.data.token;
  } else {
    throw new Error("Invalid data from sso-login endpoint");
  }
}
