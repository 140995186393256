import React from "react";
import { useNavigate } from "react-router-dom";
import { isNumber, isObject, isString } from "./narrowing";

/**
 * Costruisce il nome della chiave del bookmark automatico
 */
export function getAutomaticBookmarkName(isbn: string): string {
  return `${isbn}_ab`;
}

/**
 * Costruisce il nome della chiave del bookmark manuale
 */
export function getManualBookmarkName(isbn: string): string {
  return `${isbn}_mb`;
}

export interface BookmarkBase {
  chapterId: string;
  scrollY: number;
  percentage: number;
}

export interface BookmarkExtra {
  chapterTitle?: string | undefined;
}

export type Bookmark = BookmarkBase & BookmarkExtra;

function isBookmarkBase(data: unknown): data is BookmarkBase {
  return (
    isObject(data) &&
    "chapterId" in data &&
    isString(data.chapterId) &&
    "scrollY" in data &&
    isNumber(data.scrollY) &&
    "percentage" in data &&
    isNumber(data.percentage)
  );
}

function objectIsBookmarkExtra(
  data: NonNullable<unknown>,
): data is BookmarkExtra {
  return !("chapterTitle" in data) || isString(data.chapterTitle);
}

/**
 * Legge il bookmark data la chiave. Ritorna null se non esiste o se non è valido.
 */
export function loadBookmark(name: string): Bookmark | null {
  try {
    const raw = localStorage.getItem(name);
    if (raw === null) {
      return null;
    }
    const out: unknown = JSON.parse(raw);
    if (isBookmarkBase(out) && objectIsBookmarkExtra(out)) {
      return out;
    } else {
      localStorage.removeItem(name);
      return null;
    }
  } catch {
    localStorage.removeItem(name);
    return null;
  }
}

/**
 * Salva il bookmark
 */
export function saveBookmark(
  name: string,
  chapterId: string,
  extraData: BookmarkExtra = {},
): Bookmark | undefined {
  try {
    const scrollTop = window.scrollY;
    const docHeight = document.body.scrollHeight;
    const winHeight = window.innerHeight;
    const scrollPercent =
      docHeight > winHeight ? scrollTop / (docHeight - winHeight) : 1;

    const data = {
      chapterId,
      scrollY: window.scrollY,
      percentage: Math.round(scrollPercent * 100),
      ...extraData,
    };

    localStorage.setItem(name, JSON.stringify(data));
    return data;
  } catch {
    // Non esegue nulla
    return;
  }
}

/**
 * Alla chiusura della pagina del browser salva il progresso di lettura.
 */
export function useAutomaticBookmark(isbn: string, chapterId: string): void {
  React.useEffect(() => {
    const listener = () => {
      if (isbn && chapterId) {
        saveBookmark(getAutomaticBookmarkName(isbn), chapterId);
      }
    };

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [isbn, chapterId]);
}

export interface ManualBookmark {
  manualBookmark: Bookmark | null | undefined;
  updateManualBookmark: (chapterTitle?: string) => void;
  navigateToManualBookmark: () => void;
}

/**
 * Hook che ritorna i dati relativi al segnalibro manuale, la funzione per settarlo e la funzione
 * per navigare verso il segnalibro.
 */
export function useManualBookmark(
  isbn: string,
  chapterId: string,
): ManualBookmark {
  const [manualBookmark, setManualBookmark] = React.useState<
    Bookmark | null | undefined
  >(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    setManualBookmark(loadBookmark(getManualBookmarkName(isbn)));
  }, [isbn]);

  const updateManualBookmark = (chapterTitle?: string) => {
    setManualBookmark(
      saveBookmark(getManualBookmarkName(isbn), chapterId, { chapterTitle }),
    );
  };

  const navigateToManualBookmark = () => {
    if (manualBookmark) {
      navigate(manualBookmark.chapterId, {
        state: { scrollY: manualBookmark.scrollY },
      });
    }
  };

  return {
    manualBookmark,
    updateManualBookmark,
    navigateToManualBookmark,
  };
}
