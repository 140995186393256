import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useUpdateEffect } from "react-use";
import {
  MdMenu,
  MdChevronRight,
  MdBookmarkAdd,
  MdBookmark,
  MdDelete,
  MdAdd,
  MdClose,
} from "react-icons/md";
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import * as Accordion from "@radix-ui/react-accordion";
import { Link, useLocation, useParams } from "react-router-dom";

import Icon from "~/src/components/Icon/";
import Button from "../../components/Button";
import useVisibleHeadingContext from "../../Editor/Blocks/Heading/useVisibleHeadingContext";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../../components/Tooltip";
import { ReadonlyDeep } from "~/src/utils/reflection";
import { useBookContext } from "~/src/Pages/Book";

export interface SectionNode {
  id: number;
  title: string;
  children: SectionNode[];
  slug?: undefined | string;
  flatChildrenSlugs?: undefined | string[];
}

export interface Permissions {
  canDeleteChapter?: undefined | boolean;
  canCreateChapter?: undefined | boolean;
}

interface ConfirmationModalProps {
  triggerIcon: ReactNode;
  ariaLabel: string;
  text: string;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  tooltipText: ReactNode;
}

function ConfirmationModal({
  triggerIcon,
  ariaLabel,
  text,
  onConfirm,
  tooltipText,
}: ReadonlyDeep<ConfirmationModalProps>) {
  return (
    <Dialog.Root>
      <Tooltip>
        <TooltipTrigger asChild>
          <Dialog.Trigger asChild>
            <Button type="ghost" size="xs" ariaLabel={ariaLabel}>
              {triggerIcon}
            </Button>
          </Dialog.Trigger>
        </TooltipTrigger>
        <TooltipContent sideOffset={2} side="top">
          {tooltipText}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
      <Dialog.Overlay className="fixed w-full h-full bg-gray-900 opacity-50" />
      <Dialog.Content
        className="sidebar-modal"
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <Dialog.Description>{text}</Dialog.Description>
        <div className="sidebar-modal__actions">
          <Dialog.Close asChild>
            <Button type="ghost">Annulla</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button type="primary" onClick={onConfirm}>
              Conferma
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

interface SectionRowProps {
  node: SectionNode;
  children?: undefined | ReactNode;
  permissions?: undefined | Permissions;
}

function SectionRow({
  children,
  node,
  permissions,
}: ReadonlyDeep<SectionRowProps>) {
  const { createChapter, deleteChapter } = useBookContext();

  return (
    <div className="sidebar-row">
      <p className="sidebar-row__title">{children}</p>
      {
        // Reason: we check for both nullability and logic true
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (permissions?.canCreateChapter || permissions?.canDeleteChapter) && (
          <div className="sidebar-row__actions">
            {permissions.canCreateChapter && (
              <ConfirmationModal
                text="Sei sicuro di voler creare un capitolo?"
                ariaLabel="aggiungi capitolo"
                triggerIcon={<Icon icon={MdAdd} size="20px" />}
                onConfirm={() => void createChapter(node)}
                tooltipText="Crea capitolo"
              />
            )}
            {permissions.canDeleteChapter && (
              <ConfirmationModal
                text="Sei sicuro di voler cancellare il capitolo?"
                ariaLabel="cancella capitolo"
                triggerIcon={<Icon icon={MdDelete} size="20px" />}
                onConfirm={() => void deleteChapter(node)}
                tooltipText="Cancella capitolo"
              />
            )}
          </div>
        )
      }
    </div>
  );
}

interface SectionHeaderProps {
  node: SectionNode;
  link: string;
  isActive?: undefined | boolean;
  permissions?: undefined | Permissions;
  isOpen?: undefined | boolean;
}

function SectionHeader({
  node,
  link,
  permissions = {},
  isActive = false,
  isOpen = false,
}: ReadonlyDeep<SectionHeaderProps>) {
  const anchorClass = classNames("sidebar__link", {
    "sidebar__link--active": isActive,
  });
  return (
    <Accordion.Header asChild>
      <div className="sidebar__header">
        <Accordion.Trigger asChild>
          <Button type="ghost" size="xs" classes="sidebar__chevron">
            <Icon
              icon={MdChevronRight}
              size="20px"
              aria-label={`${isOpen ? "Chiudi" : "Apri"} sezione ${node.title}`}
            />
          </Button>
        </Accordion.Trigger>
        <SectionRow node={node} permissions={permissions}>
          <Link className={anchorClass} to={link}>
            {node.title}
          </Link>
        </SectionRow>
      </div>
    </Accordion.Header>
  );
}

const EMPTY_OBJ = {};

export interface SectionProps {
  node: SectionNode;
  isRoot?: undefined | boolean;
  chapterId: number;
  permissions?: undefined | Permissions;
  level?: undefined | number;
}

function Section({
  node,
  isRoot = false,
  permissions = EMPTY_OBJ,
  chapterId,
  level = 0,
}: ReadonlyDeep<SectionProps>) {
  const { furthestVisibleHeading } = useVisibleHeadingContext();
  const isActive = furthestVisibleHeading.id === node.slug;
  const isChildActive = node.flatChildrenSlugs?.includes(
    furthestVisibleHeading.id,
  );
  const link = isRoot
    ? `../${chapterId}`
    : `../${chapterId}${node.slug === undefined ? "" : `#${node.slug}`}`;

  const [isOpen, setIsOpen] = React.useState(
    !furthestVisibleHeading.disabled && (isActive || isChildActive),
  );

  const listItemClass = classNames("", {
    "mt-2": !isRoot,
    "mt-4": isRoot,
    "pl-2": node.children.length === 0,
  });
  const anchorClass = classNames("sidebar__link", {
    "sidebar__link--active": isActive,
    "pl-6": !isRoot,
    "pl-2": isRoot,
  });

  React.useEffect(() => {
    setIsOpen((prev) => {
      if (!prev && (isActive || isChildActive)) return true;

      return prev;
    });
  }, [isChildActive, isActive]);

  const accordionRootOnValueChange = useCallback((value) => {
    setIsOpen(!!value);
  }, []);

  if (node.children.length === 0) {
    return (
      <li className={listItemClass}>
        <SectionRow node={node} permissions={permissions}>
          <Link className={anchorClass} to={link}>
            {node.title}
          </Link>
        </SectionRow>
      </li>
    );
  }

  const id = `${node.title}-${node.id}-${level}`;

  return (
    <Accordion.Root
      asChild
      collapsible
      type="single"
      value={isOpen ? id : ""}
      onValueChange={accordionRootOnValueChange}
    >
      <Accordion.Item value={id} asChild>
        <li className={listItemClass}>
          <SectionHeader
            node={node}
            isActive={isActive}
            isOpen={isOpen}
            link={link}
            permissions={permissions}
          />
          <Accordion.Content asChild>
            <ul className="ml-4">
              {node.children.map((section) => {
                const sectionId = `${section.title}-${section.id}-${level + 1}`;
                return (
                  <Section
                    chapterId={chapterId}
                    node={section}
                    key={sectionId}
                    level={level + 1}
                  />
                );
              })}
            </ul>
          </Accordion.Content>
        </li>
      </Accordion.Item>
    </Accordion.Root>
  );
}

function SideBar({
  bookSummary,
  chapterTitle,
  isReadOnly,
}: ReadonlyDeep<Props>) {
  const [isOpen, setIsOpen] = React.useState(false);

  const { hash, pathname } = useLocation();
  useEffect(() => {
    const section = hash.replace("#", "");
    setIsOpen(false);
    if (section && section !== "") {
      const chaptById = document.getElementById(section);
      const heading = chaptById?.querySelector(
        "* > :where(h2, h3, h4, h5, h6)",
      );

      if (heading) {
        // Su Safari non si riesce a fare focus senza tabindex
        heading.setAttribute("tabindex", "-1");
        if (heading instanceof HTMLElement) {
          heading.focus();
        }
      }
    }
  }, [hash]);

  useUpdateEffect(() => {
    setTimeout(() => {
      const heading = document.querySelector("main h2");
      if (heading) {
        // Su Safari non si riesce a fare focus senza tabindex
        heading.setAttribute("tabindex", "-1");
        if (heading instanceof HTMLElement) {
          heading.focus();
        }
      }
      setIsOpen(false);
    }, 100);
  }, [pathname]);

  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (evt.code === "KeyS" && evt.shiftKey && evt.altKey) {
        setIsOpen((v) => !v);
      }
    };
    document.addEventListener("keyup", listener);

    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, []);

  const { manualBookmark, updateManualBookmark, navigateToManualBookmark } =
    useBookContext();

  const permissions = {
    canCreateChapter: !isReadOnly,
    canDeleteChapter: !isReadOnly && bookSummary.length > 1,
  };

  const { isbn = "" } = useParams();
  const iwIsbn = useMemo(() => {
    const underscoreIndex = isbn.indexOf("_");
    if (underscoreIndex === -1) {
      return isbn;
    } else {
      return isbn.substring(0, underscoreIndex);
    }
  }, [isbn]);

  return (
    <Dialog.Root
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <Dialog.Trigger className="p-2 flex btn btn-ghost rounded-btn">
        <Icon
          aria-keyshortcuts="Alt+Shift+S¯"
          label={`${isOpen ? "Chiudi" : "Apri"} sommario`}
          icon={isOpen ? MdClose : MdMenu}
          className="w-8 h-8"
        />
      </Dialog.Trigger>

      <Dialog.Content
        className="sidebar__content"
        onCloseAutoFocus={(evt) => {
          evt.preventDefault();
          return false;
        }}
      >
        <Dialog.Close asChild>
          <button className="sr-only">Chiudi sommario</button>
        </Dialog.Close>
        <a
          href={`https://iwpiu.loescher.it/imparosulweb/${iwIsbn}`}
          target="_blank"
          rel="noreferrer"
          className="block mb-2"
        >
          <span className="underline underline-offset-2">Apri libro su</span>{" "}
          <span
            className="rounded px-1"
            style={{ backgroundColor: "#ffb400" }}
            aria-label="IwPiù"
          >
            <IwPiu />
          </span>
        </a>

        <Dialog.Title className="font-bold">Segnalibro</Dialog.Title>
        <div className="my-2 space-x-1.5">
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              updateManualBookmark(chapterTitle);
            }}
          >
            {manualBookmark ? "Aggiorna" : "Salva"}
            <Icon
              className="ml-1"
              label="salva segnalibro"
              icon={MdBookmarkAdd}
              size="20px"
            />
          </Button>
          {manualBookmark && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="primary"
                  size="sm"
                  disabled={!manualBookmark}
                  onClick={navigateToManualBookmark}
                  ariaLabel="vai al segnalibro"
                >
                  Vai a
                  <Icon
                    className="ml-1"
                    label="vai al segnalibro"
                    icon={MdBookmark}
                    size="20px"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={2} side="right">
                {`${manualBookmark.percentage}%`}
                {manualBookmark.chapterTitle !== undefined &&
                  ` di "${manualBookmark.chapterTitle}"`}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip>
          )}
        </div>

        <Dialog.Title className="font-bold">Sommario</Dialog.Title>
        <ul>
          {bookSummary.map((section) => (
            <Section
              isRoot
              chapterId={section.id}
              key={section.id}
              node={section}
              permissions={permissions}
            />
          ))}
        </ul>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function IwPiu(): ReactElement {
  return (
    <svg className="inline" height="1em" viewBox="0 0 47.7 19.8">
      <g fill="#0d0e0e" strokeWidth=".637">
        <path d="m0 16 1.4-7.9h2.87l-1.34 7.9zm1.78-9.75 0.319-2.04h3.12l-0.765 2.68z" />
        <path d="m18 8.1-3.82 7.9h-3.06c-0.255-0.574-0.51-1.66-0.765-2.93l-1.53 2.93h-2.87c-0.319-0.956-0.829-3.19-0.829-5.48 0-0.829 0.0637-1.66 0.191-2.42h2.93c-0.127 0.956-0.191 1.78-0.191 2.61 0 0.828 0.0637 1.53 0.128 2.36h0.191l1.59-3.31c0-0.574 0.0638-1.15 0.191-1.66h2.93c-0.127 0.956-0.191 1.78-0.191 2.61 0 0.828 0.0639 1.53 0.127 2.36h0.191l1.85-4.97z" />
        <path d="m20 2.68h5.8c2.87 0 4.46 1.78 4.46 4.4 0 2.8-2.29 5.48-6.69 5.48h-0.892l-0.574 3.44h-4.4zm3.82 3.31-0.574 3.31c1.47-0.0638 2.36-0.51 2.36-1.91 0-0.892-0.574-1.34-1.78-1.4z" />
        <path d="m30 16 1.91-10.5h3.82l-1.78 10.5zm2.36-13 0.382-2.68h4.21l-1.02 3.57z" />
        <path d="m47.7 5.23-1.91 10.5h-3.82l0.319-1.78c-1.4 1.34-2.61 2.04-4.14 2.04-1.21 0-1.91-0.956-1.91-2.55 0-0.383 0.0638-0.829 0.127-1.21l1.15-6.95h3.95l-0.829 4.84c-0.128 0.701-0.192 1.53-0.0638 2.04l0.318 0.0638c0.51-0.191 1.21-0.51 1.98-1.08l1.02-5.93zm-6.06-1.59-3.51-3.63h3.63l2.55 3.63z" />
      </g>
    </svg>
  );
}

export interface Props {
  bookSummary: SectionProps["node"][];
  chapterTitle: string;
  isReadOnly?: boolean | undefined;
}

export default SideBar;
