import React from "react";

import { FadeAnimation } from "../../utils/animations";
import { ReadonlyDeep } from "~/src/utils/reflection";

export interface Props {
  buttons?: HTMLButtonElement[] | undefined;
}

const EMPTY_BUTTONS: readonly ReadonlyDeep<HTMLButtonElement>[] = [];

function Toolbar({ buttons = EMPTY_BUTTONS }: ReadonlyDeep<Props>) {
  return (
    <FadeAnimation in={!!buttons.length}>
      <div className="editor-toolbar" data-testid="editor-toolbar">
        {buttons}
      </div>
    </FadeAnimation>
  );
}

export default Toolbar;
