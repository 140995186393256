import React, { ComponentProps, ReactNode } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export interface PopoverProps
  extends ComponentProps<typeof PopoverPrimitive.Root> {
  children: ReactNode;
}

export function Popover({ children, ...props }: PopoverProps) {
  return <PopoverPrimitive.Root {...props}>{children}</PopoverPrimitive.Root>;
}

export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverClose = PopoverPrimitive.Close;

export interface PopoverContentProps
  extends ComponentProps<typeof PopoverPrimitive.Content> {
  children: ReactNode;
}

export const PopoverContent = ({ children, ...props }: PopoverContentProps) => {
  return (
    <PopoverPrimitive.Content className="popover__content" {...props}>
      {children}
    </PopoverPrimitive.Content>
  );
};
