import React, { useState, useCallback, ReactElement } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import {
  readToken,
  saveToken,
  deleteToken,
  parseTokenData,
  getFreshToken,
  saveLoescherSsoToken,
  deleteLoescherSsoToken,
  readLoescherSsoToken,
  Token,
} from "./MylimAuthentication";
import {
  login as loginCall,
  LoginOutput,
  loginWithSsoToken,
} from "./MylimAuthenticationService";
import { Api } from "../Api";
import Spinner from "../components/Spinner";
import { isObject } from "../utils/narrowing";

export interface UserInfo {
  readonly email: string;
  readonly role: string;
  readonly canEdit: boolean;
}

export interface AuthContextData {
  readonly userInfo: UserInfo;
  readonly login: (
    username: string,
    password: string,
    rememberMe: boolean | undefined,
  ) => Promise<void>;
  readonly logout: () => void;
}

const defaultUserInfo: UserInfo = {
  email: "",
  role: "Studente",
  canEdit: false,
} as const;

export const AuthContext = React.createContext<AuthContextData>({
  userInfo: defaultUserInfo,
  login: () => Promise.resolve(),
  logout: () => undefined,
});

const SSO_TOKEN_MAX_AGE_SECONDS = 92 * 24 * 3600; // 92 days ~= 3 months.

export interface Props {
  readonly children: ReactElement;
}

/**
 * Fornisce il Provider del context delle info dell'utente e il wrapper per
 * le chiamate api che gestisce il refresh e la gestione del token JWT.
 */
function AuthProvider({ children }: Props): ReactElement {
  const [initTokenDone, setInitTokenDone] = React.useState(false);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const navigate = useNavigate();

  const initToken = useCallback(async () => {
    // Leggiamo il token
    const tokenB = readToken();
    let token = parseTokenData(tokenB);
    token = await getFreshToken(token);
    if (!token) {
      token = await tryLoginWithSsoToken();
      if (token === null) {
        // Dobbiamo loggarci
        setInitTokenDone(true);
        return;
      }
    }
    // Autenticati !!!

    setUserInfo({
      email: token.data.email,
      role: token.data.loescher_roles[0] ?? "",
      canEdit: token.data.loescher_roles.includes("Redattore"),
    });

    // Refresh dell'interceptor axios con il token
    Api.interceptors.request.use(async (config) => {
      const jwt = await getFreshToken(token);
      if (jwt !== null) {
        config.headers.Authorization = `JWT ${jwt.token}`;

        if (token.token !== jwt.token) {
          await initToken();
        }
      }
      return config;
    });
    setInitTokenDone(true);
  }, []);

  // All'apertura dell'app
  React.useEffect(() => {
    initToken().catch((reason: unknown) => {
      console.error(reason);
    });
  }, [initToken]);

  const login = useCallback(
    async (
      username: string,
      password: string,
      rememberMe: boolean | undefined,
    ) => loginImpl(setInitTokenDone, initToken, username, password, rememberMe),
    [initToken],
  );

  const logout = () => {
    deleteToken();
    deleteLoescherSsoToken();
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ userInfo, login, logout }}>
      {initTokenDone ? children : <Spinner />}
    </AuthContext.Provider>
  );
}

export async function loginImpl(
  setInitTokenDone: (value: React.SetStateAction<boolean>) => void,
  initToken: () => Promise<void>,
  username: string,
  password: string,
  rememberMe: boolean | undefined,
): Promise<void> {
  let loginReturn: LoginOutput;
  try {
    loginReturn = await loginCall(username, password);
  } catch (err) {
    if (
      isObject(err) &&
      "response" in err &&
      isObject(err.response) &&
      "status" in err.response
    ) {
      if (err.response.status === 400) {
        throw Error(
          "Inserire utente e password prima di procedere con il login",
        );
      } else if (err.response.status === 401) {
        throw Error("Impossibile accedere con le credenziali specificate.");
      }
    }
    throw Error("Si è verificato un errore, riprovare più tardi.");
  }

  const { token, token_loescher } = loginReturn;

  saveToken(token);
  saveLoescherSsoToken(
    token_loescher,
    rememberMe ? SSO_TOKEN_MAX_AGE_SECONDS : undefined,
  );
  setInitTokenDone(false);
  await initToken();
}

async function tryLoginWithSsoToken(): Promise<Token | null> {
  const ssoToken = readLoescherSsoToken();
  if (ssoToken === null) {
    return null;
  }

  let token: string;
  try {
    token = await loginWithSsoToken(ssoToken);
    if (!token) {
      deleteLoescherSsoToken();
      return null;
    }
  } catch {
    deleteLoescherSsoToken();
    return null;
  }

  saveToken(token);

  return parseTokenData(token);
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth(): AuthContextData {
  return React.useContext(AuthContext);
}

export default AuthProvider;
