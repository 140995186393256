import React, { FunctionComponent, ReactElement } from "react";
import * as AccessibleIcon from "@radix-ui/react-accessible-icon";

export type Props<P extends NonNullable<unknown>> = P extends {
  readonly icon: unknown;
  readonly label: unknown;
}
  ? never
  : {
      readonly icon: FunctionComponent<P>;
      readonly label?: string | undefined;
    } & P;

function Icon<P extends NonNullable<unknown>>({
  icon: IconGlyph,
  label,
  ...props
}: Props<P>): ReactElement {
  return label ? (
    <AccessibleIcon.Root label={label}>
      <IconGlyph {...props} />
    </AccessibleIcon.Root>
  ) : (
    <IconGlyph {...props} />
  );
}

export default Icon;
