import React, { PropsWithChildren } from "react";
import { Editor } from "slate";
import { useSlate } from "slate-react";
import {
  MdFormatBold,
  MdFormatItalic,
  MdCode,
  MdFormatSize,
} from "react-icons/md";

import { isMarkActive } from "../utils";
import ButtonBase from "../../components/Button";
import Icon from "../../components/Icon";
import { ReadonlyDeep } from "~/src/utils/reflection";

export interface Props {
  format: string;
  label?: string | undefined;
}

function ToolbarBtnFormat({
  format,
  children,
  label,
}: ReadonlyDeep<PropsWithChildren<Props>>) {
  const editor = useSlate();

  const isActive = isMarkActive(editor, format);

  const onToggle = () => {
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return (
    <ButtonBase
      square
      type="primary"
      outline={!isActive}
      ariaLabel={label}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onToggle}
    >
      {children}
    </ButtonBase>
  );
}

export const ToolbarBtnFormatItalic = () => (
  <ToolbarBtnFormat format="italic" label="italico">
    <Icon label="italico" icon={MdFormatItalic} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatBold = () => (
  <ToolbarBtnFormat format="bold" label="grassetto">
    <Icon label="grassetto" icon={MdFormatBold} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatCode = () => (
  <ToolbarBtnFormat format="code" label="codice">
    <Icon label="codice" icon={MdCode} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatUppercase = () => (
  <ToolbarBtnFormat format="uppercase" label="maiuscolo">
    <Icon label="maiuscolo" icon={MdFormatSize} size="30px" />
  </ToolbarBtnFormat>
);

export default ToolbarBtnFormat;
