export function isString(data: unknown): data is string {
  return typeof data === "string";
}

export function isNumber(data: unknown): data is number {
  return typeof data === "number";
}

export function isBoolean(data: unknown): data is boolean {
  return typeof data === "boolean";
}

export function isNullableObject(
  data: unknown,
): data is NonNullable<unknown> | null {
  return typeof data === "object";
}

export function isObject(data: unknown): data is NonNullable<unknown> {
  return typeof data === "object" && data !== null;
}

export function isArrayOf<T>(
  data: unknown,
  checker: (data: unknown) => data is T,
): data is T[] {
  return Array.isArray(data) && data.every(checker);
}
